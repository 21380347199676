.video-react-big-play-button {
  position: absolute;
  left: 50% !important;
  top: 50% !important;
  margin-left: -44px;
  margin-top: -21px;
}
.video-size-landing {
  width: 88vw;
  height: 66vw;
}
@media (min-aspect-ratio: 4/3) {
  .video-size-landing {
    width: 108vh;
    height: 81vh;
  }
}

.draggable-resizable-modal-manual {
  position: absolute;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  cursor: move;
}

.modal-content-manual {
  flex-grow: 1;
  padding: 20px;
}

.resize-handle-manual {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
  background-color: #007bff;
}

.drag-handle-manual {
  width: 100%;
  height: 20px;
  position: relative;
  cursor: grab;
  background-color: #007bff;
}
