.img-animation1 {
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  animation-name: scale-in1;
  animation-duration: 2s;
}
.img-animation2 {
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  animation-name: scale-in2;
  animation-duration: 3s;
}
.img-animation3 {
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  animation-name: scale-in3;
  animation-duration: 4s;
}
.img-animation4 {
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  animation-name: scale-in4;
  animation-duration: 5s;
}

@keyframes scale-in1 {
  0% {
    background-size: 0%;
  }
  75% {
    background-size: 0%;
  }
  100% {
    background-size: 100%;
  }
}
@keyframes scale-in2 {
  0% {
    background-size: 0%;
  }
  83% {
    background-size: 0%;
  }
  100% {
    background-size: 100%;
  }
}
@keyframes scale-in3 {
  0% {
    background-size: 0%;
  }
  87.5% {
    background-size: 0%;
  }
  100% {
    background-size: 100%;
  }
}
@keyframes scale-in4 {
  0% {
    background-size: 0%;
  }
  90% {
    background-size: 0%;
  }
  100% {
    background-size: 100%;
  }
}
