.swiper-pagination-bullet {
  width: 14px !important;
  height: 14px !important;
  border: solid 1px #555 !important;
  background-color: #ffffff !important;
}

.swiper-pagination-bullet-active {
  background-color: #ccc !important;
  border: solid 1px #ccc !important;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 40px !important;
}
.swiper-slide {
  height: auto !important;
}
