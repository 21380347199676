.fade-in {
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -o-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tag {
  position: absolute;
  left: -90px;
  top: 11px;
  width: 220px;
  height: 16px;
  line-height: 16px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-with-ribbon {
  position: absolute;
  left: -93px;
  top: -4px;
  width: 220px;
  height: 40px;
  line-height: 20px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 20px;
}

.ribbon {
  position: absolute;
  left: -73px;
  top: 27px;
  width: 220px;
  height: 20px;
  line-height: 20px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
